import Phaser from 'phaser';
import { phaserConfig } from './phaser.config';
import VerseScene from './scenes/VerseScene';
import { FONT_MONTSERRAT_MEDIUM, FONT_PLAYFAIR_MEDIUM } from './app/const/const';
import { LoadFont } from './utils/LoadFont';

// LoadFont(FONT_MONTSERRAT_MEDIUM, "./fonts/Montserrat-Medium.ttf", () => {
  LoadFont(FONT_PLAYFAIR_MEDIUM, "./fonts/PlayfairDisplay-Medium.ttf", () => {
//     LoadFont(AppSettings.fontFamilies.MontserratBlack, "./fonts/Montserrat-Black.ttf", () => {
      new Phaser.Game(
        Object.assign(phaserConfig, {
          scene: [
            VerseScene,
          ],
        })
      );
//     });
  });
// });