export enum AppDebugLogType {
	GENERIC,
	/**
	 * For QA team
	 */
	DEBUG,
	/**
	 * Google Tag Manager logs
	 */
	GTM,
	/**
	 * Debug cleanup message
	 */
	DESTROY,
}

/**
 * Debug flags to debug app
 * Source:
 * > GET params (html doc)
 * > code?
 */
export class AppDebug {
	public static DEBUG_LOGS: boolean = true;
	public static DEBUG_GETATTRIBS: boolean = true;
	private static urlSearchParams: URLSearchParams;

	public static log(message: string | string[], logType: AppDebugLogType = AppDebugLogType.GENERIC): void {
		switch (logType) {
			case AppDebugLogType.GENERIC:
				if (AppDebug.DEBUG_LOGS) {
					console.log(...message); // default color
				}
				break;
			case AppDebugLogType.DESTROY:
				if (AppDebug.DEBUG_LOGS) {
					console.log('%c' + message, 'color:orange');
				}
				break;
		}
	}

	public static Init(): void {
		// get and parse GET params
		// Address of the current window
		const address: string = window.location.search;

		// Returns a URLSearchParams object instance
		this.urlSearchParams = new URLSearchParams(address);
	}

	public static GetVerseIndex(defaultValue: number): number {
		if (this.DEBUG_GETATTRIBS) {
			const targetParam: number = parseInt(this.urlSearchParams.get('v'));
			return Number.isNaN(targetParam) ? defaultValue : targetParam;
		}
		return defaultValue;
	}
}
