/**
 * Typed WebStorage/localStorage @see https://www.w3schools.com/html/html5_webstorage.asp
 * API similar to: @see https://docs.unity3d.com/ScriptReference/PlayerPrefs.html
 * 
 * BASICS: STORING DATA -----------------------------------
 * 
 * 1) Storing local data (synchronously):
 * 
 * 1A) Web Local Data Storage:
 *  API:
 *      https://developer.mozilla.org/en-US/docs/Web/API/Storage
 *      https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 *      inspiration: https://docs.unity3d.com/ScriptReference/PlayerPrefs.html
 *  Guide:
 *      https://www.dynetisgames.com/2018/10/28/how-save-load-player-progress-localstorage/
 *      https://usefulangle.com/post/219/javascript-webstorage-api
 *  NaN, parseInt, parseFloat, Number(): https://cullenwebservices.com/javascript-numbers-number-parseint-parsefloat/
 *  Support:
 *      https://caniuse.com/namevalue-storage
 *      https://caniuse.com/indexeddb
 * 
 * 1B) App Data Storage:
 * > Seems that 1A) will work here also
 * 
 * 2) TESTS:
 * A) 0 || default returns default (although we might want 0) -- CANT USE ||
 * B) localStorage.getItem("i_dont_exist") returns null
 * C) parseInt(localStorage.getItem("i_dont_exist")) returns NaN
 * Solution: compare result of getItem() to null first.
 */
export class LocalStorage
{
    // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
    public static IsAvailable(): boolean {
        var test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch(e) {
            return false;
        }
    }

    /**
     * NOTE: value.toString() throws TypeError if value=null or undefined; String(value) does not;
     *       https://stackoverflow.com/questions/3945202/whats-the-difference-between-stringvalue-vs-value-tostring
     * @param key 
     * @param value 
     */
    public static SetInt(key: string, value:number): void
    {
        localStorage.setItem(key, String(value));
    }
    public static SetFloat(key: string, value:number): void
    {
        localStorage.setItem(key, String(value));
    }
    public static SetString(key: string, value:string): void
    {
        localStorage.setItem(key, value);
    }
    public static SetObject<T>(key:string, value:T): void
    {
        localStorage.setItem(key, JSON.stringify(value));
    }
    /**
     * Returns the value corresponding to key in the preference file if it exists.
     * If it doesn't exist, it will return defaultValue.
     * https://docs.unity3d.com/ScriptReference/PlayerPrefs.GetInt.html
     * 
     * TESTS:
     * 1) 0 || default returns default (although we might want 0) -- CANT USE ||
     * 2) localStorage.getItem("i dont exist") returns null
     * 3) parseInt(localStorage.getItem("i dont exist")) returns NaN
     */
    public static GetInt(key: string, defaultValue: number): number
    {
        const result: string | null = localStorage.getItem(key);
        return result != null ? parseInt(result) : defaultValue;
    }
    public static GetFloat(key: string, defaultValue: number): number
    {
        const result: string | null = localStorage.getItem(key);
        return result != null ? parseFloat(result) : defaultValue;
    }
    public static GetString(key: string, defaultValue: string): string
    {
        const result: string | null = localStorage.getItem(key);
        return result != null ? result : defaultValue;
    }
    public static GetObject<T>(key: string, defaultValue: T = null): T | null
    {
        let result: any = localStorage.getItem(key);
        result = result && (JSON.parse(result as any) as T)
        return result != null ? result : defaultValue;
    }
    /**
     * 
     * getItem returns null if value not found: https://developer.mozilla.org/en-US/docs/Web/API/Storage/getItem
     * @param key 
     * @returns 
     */
    public static HasKey(key: string): boolean
    {
        // console.log("HasKey", key, localStorage.getItem(key))
        return localStorage.getItem(key) != null;
    }
    public static DeleteItem(key: string): void
    {
        localStorage.removeItem(key);
    }
    public static DeleteAll(): void
    {
        localStorage.clear();
    }
}