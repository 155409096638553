import { AppLocalStorage } from '../app/data/AppLocalStorage';
import { FONT_PLAYFAIR_MEDIUM, VERSES } from '../app/const/const';
import { GetFixedIndex } from '../utils/GetFixedIndex';
import { AppDebug } from '../app/debug/AppDebug';

export default class VerseScene extends Phaser.Scene {
	public verseText: Phaser.GameObjects.Text;

	public constructor() {
		super('verse');
	}

	public init(): void {
		this.input.mouse.disableContextMenu();
		AppDebug.Init();
	}

	public preload(): void {
		// const path: string = "./atlas";
		// this.load.multiatlas("ui", path+"/ui-s100.json", path);
		this.load.image('fx-sparkle-01', './img/fx-sparkle-01.png');
	}

	public create(): void {
		// const c = this.add.container();
		// const view =  new VerseView(c);
		// const sceneW = this.game.canvas.width;
		// const sceneH = this.game.canvas.height;
		// view.setPosition(sceneW * 0.5, sceneH * 0.5);

		const sceneCenterX = this.game.canvas.width * 0.5;
		const sceneCenterY = this.game.canvas.height * 0.5;

		// const fxWinBg = this.make.particles({
		//     key: "ui",
		//     x: sceneCenterX,
		//     y: sceneCenterY,
		//     config: {
		//         frame: "fx-glow-01.png",
		//         lifespan: 1600,
		//         frequency: 50,
		//         quantity: 3,
		//         rotate: { onEmit: () => Math.random() * 360 },
		//         scaleX: { start: 0, end: 2.5 },
		//         scaleY: { start: 0, end: 12 },
		//         alpha: { start: 0.4, end: 0 },
		//         emitting: false,
		//     }
		// });
		// fxWinBg.scaleY = 0.5;
		// this.add.existing(fxWinBg);

		const speedX = 100;
		const speedY = 50;
		const fxWinFg = this.make.particles({
			// key: "ui",
			key: 'fx-sparkle-01',
			x: sceneCenterX,
			y: sceneCenterY,
			config: {
				// frame: "fx-sparkle-01.png",
				speedX: { onEmit: () => -speedX + Math.random() * speedX * 2 },
				speedY: { onEmit: () => -speedY + Math.random() * speedY * 2 },
				// speed: { onEmit: () => -1000 + Math.random() * 2000 },
				lifespan: 5000,
				quantity: 10,
				frequency: 50,
				rotate: { onEmit: () => Math.random() * 360 },
				scale: { start: 0.8, end: 0 },
				alpha: { start: 0.05, end: 0.3 },
				emitting: true,
				advance: 10000,
			},
		});
		// this.add.existing(fxWinFg);

		this.verseText = this.make.text({
			x: sceneCenterX,
			y: sceneCenterY,
			style: {
				color: '#fff',
				fontSize: '45px',
				// fontFamily: FONT_MONTSERRAT_MEDIUM,
				fontFamily: FONT_PLAYFAIR_MEDIUM,
				stroke: '#333',
				strokeThickness: 3,
				align: 'center',
				metrics: { ascent: 40.905002170138886, descent: 8.774997287326393, fontSize: 49.67999945746528 },
			},
			origin: 0.5,
		});
		this.add.existing(this.verseText);

		this.ShowVerse();
	}

	private ShowVerse(): void {
		let nextVerseIndex = AppDebug.GetVerseIndex(-1);
		if (nextVerseIndex == -1) {
			const prevVerseIndex = AppLocalStorage.GetLastVerseIndex(this.GetRandomVerseIndex());
			nextVerseIndex = GetFixedIndex(VERSES.length, prevVerseIndex + 1);
		}
		// console.log(prevVerseIndex, nextVerseIndex);

		AppLocalStorage.SetLastVerseIndex(nextVerseIndex);
		this.verseText.text = this.GetVerseByIndex(nextVerseIndex);
		// console.log(this.verseText.text)
	}

	private GetVerseByIndex(index: number): string {
		return VERSES[index];
	}

	private GetRandomVerseIndex(): number {
		return Math.floor(Math.random() * VERSES.length);
	}
}
