import { LocalStorage } from "../../data/LocalStorage";

export class AppLocalStorage {
    private static storagePrefix: string = "jk_eu_";

    public static GetLastVerseIndex(defaultValue: number): number {
        return LocalStorage.GetInt(this.storagePrefix+"lastVerseIndex", defaultValue);
    }

    public static SetLastVerseIndex(value: number): void {
        LocalStorage.SetInt(this.storagePrefix+"lastVerseIndex", value);
    }
}