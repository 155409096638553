/**
 * EDIT: still cant make it work!!!
 * (loading finishes successfully but font is not used!)
 * 
 * Load Font
 * 
 * @usage 
 *  LoadFont("mixolydian", "assets/fonts/mixolydian.ttf");
 * 
 * Function taken from 
 *  https://stackoverflow.com/questions/51217147/how-to-use-a-local-font-in-phaser-3
 * 
 * @param name 
 * @param url 
 */
export function LoadFont(name: string, url: string, onComplete: Function = undefined) {
    var newFont = new FontFace(name, `url(${url})`);
    newFont.load().then(
        function (loaded) {
            (document as any).fonts.add(loaded);
            if (onComplete != undefined) {
                onComplete();
            }
        }
    ).catch(function (error) {
        return error;
    });
}