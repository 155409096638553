export const FONT_MONTSERRAT_MEDIUM = 'MontserratMedium';
export const FONT_PLAYFAIR_MEDIUM = 'PlayFairMedium';

export const VERSES = [
	'Ježiš mu povedal:\nJa som cesta i pravda i život.\nNikto nepríde k Otcovi, iba cezo mňa.\n\nJán 14:6',

	'A Ježiš odpovedal a riekol mu:\nAmen, amen, hovorím ti.\nAk sa niekto nenarodí znova,\nnemôže uzrieť kráľovstvo Božie.\n\nJán 3:3',

	'Vtedy povedal Ježiš svojim učeníkom:\nAk chce niekto ísť za mnou,\nnech zaprie sám seba,\nvezme svoj kríž a nasleduje ma.\n\nMatúš 16:24',

	'Nikto nemôže prísť ku mne,\niba že by ho pritiahol Otec, ktorý ma poslal.\nA ja ho vzkriesim v posledný deň.\n\nJán 6:44',

	'Čisté náboženstvo\na nepoškvrnené u Boha a Otca je toto:\nnavštevovať siroty a vdovy v ich súžení\na seba ostríhať nepoškvrneného od sveta.\n\nJakub 1:27',
];
