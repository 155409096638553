import Phaser from 'phaser';
// import BendPostFX from './pipelines/BendPostFX.js';
// import ColorPostFX from './pipelines/ColorPostFX.js';
// import HueRotate from './pipelines/HueRotate.js';
// import { Plugin as NineSlicePlugin } from 'phaser3-nineslice';
// import { Plugin as NineSlicePlugin } from '@teampanfu/phaser-nineslice';


// https://newdocs.phaser.io/docs/3.55.2/Phaser.Types.Core.GameConfig
export const phaserConfig = {
  // type: Phaser.AUTO,
  // type: Phaser.CANVAS,
  type: Phaser.CANVAS,
  // type: 0, // 0=AUTO, 1=CANVAS, 2=WEBGL: https://github.com/photonstorm/phaser/blob/v3.55.2/src//const.js
  // backgroundColor: '#007601',
  backgroundColor: '#000000',
  width: 1000, // 1920,  // 1920/2 = 960
  height: 500, // 1080, // 1080/2 = 540
  // The default WebGL Batch size. Represents the number of quads that can be added to a single batch.
  // batchSize: 512,
  // antialiasGL: true,
  scale: {
    // mode: Phaser.Scale.RESIZE, // scale to resize
    mode: Phaser.Scale.FIT,
    // mode: Phaser.Scale.ENVELOP,
    autoCenter: Phaser.Scale.CENTER_BOTH, // center for ENVELOPE
    // mode: 0, // 0=NONE: https://github.com/photonstorm/phaser/blob/v3.54.0/src/scale/const/SCALE_MODE_CONST.js
    // zoom: 1, // Size of game canvas = game size * zoom
    // pixelArt: false, // Sets antialias to false and roundPixels to true.
    // roundPixels: true,
    // antialias: true,
  },
  render: {
    // pixelArt: true,
    // roundPixels: true,
    // antialias: true,
    // antialias: false,

    // WebGL
    // antialiasGL: false,
    // antialiasGL: true,
    // batchSize: 512,
    // transparent: false,

    // b12
    // https://www.patreon.com/posts/phaser-dev-log-73259650
    defaultPipeline: 'MobilePipeline',
    autoMobilePipeline: true,
    maxTextures: 16,
  },
  plugins: {
    // global: [
    //   NineSlicePlugin.DefaultCfg
    // ],
    // global: [
    //   { key: 'NineSlicePlugin', plugin: NineSlicePlugin, start: true }
    // ]
  },
  // pipeline: {
  //   'ColorPostFX': ColorPostFX, 
  //   'BendPostFX': BendPostFX, 
  //   'HueRotate': HueRotate 
  // },
  //  Open the Dev Tools
  //  The version of your game appears after the title in the banner
  title: 'Verse',
  version: "0.0.1",
  // put canvas into this element (if supplied)
  parent: 'gameParent',
};